import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import BackspaceIcon from "@material-ui/icons/Backspace";
import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { CartInfoSection, CartInfoSectionLine, CartInfoText } from "../../../components/Header/Carts/style";
import Item from "../../../components/Listing/item";
import {
    ProductListHeader,
    ProductListHeaderItem
} from "../../../components/Listing/style";
import { Subtitle, Title, Wrapper } from "../../../components/SideListening/style";
import { fetchOrderHistoryItem, fetchProductDetails } from "../../../core/redux/asyncActions/global";
import { ORDER_HISTORY_PATH } from "../../../core/routes";
import { COUNTRY_CURRENCY } from "../../../core/utils";
import { FormLabel, OrderList } from '../../Order/style';
import { BackButton } from "../style";
import { ItemDetailsModal } from "../../../components/Listing/components/ItemDetailsModal";
import { StyledCircularProgress } from "../../../components/CircularProgress";
import { setSearchQuery } from "../../../core/redux/actions/global";

export const OrderSide = memo(() => {
    const { t } = useTranslation();
    const { id = "" } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { lang } = useSelector((state) => state.global);
    const { scope: currentCountry } = useSelector(
        (state) => state.global.userData
    );

    const currentProduct = useSelector(
        (state) => state.global?.currentProduct || null
    );
    const { data, isLoading } = useSelector((state) => state.global.orderHistoryItem);

    const handleReturn = () => {
        if (history.location.pathname !== ORDER_HISTORY_PATH) {
            history.push(ORDER_HISTORY_PATH);
        }
    };

    const handleItemPopupOrderHistory = (id) => {
        dispatch(fetchProductDetails(id, 1));
    };

    useEffect(() => {
        dispatch(fetchOrderHistoryItem(id));
    }, [lang, currentCountry, dispatch, id])

    useEffect(() => {
        dispatch(setSearchQuery(""));
    }, [dispatch])

    return (
        <Wrapper>
            {currentProduct && (
                <ItemDetailsModal />
            )}
            <BackButton onClick={handleReturn}>
                <BackspaceIcon />
                {t("productsList.orderHistory.return")}
            </BackButton>
            <OrderList>
                {data &&
                    <>
                        <Title>{data.number && data.number.toUpperCase()}</Title>
                        <Grid container spacing={1}>
                            <Grid item md={3} xs={6}>
                                <FormLabel>{t("productsList.date")}</FormLabel>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <FormLabel bold>{data?.date_created}</FormLabel>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <FormLabel>{t("productsList.orderHistory.state")}</FormLabel>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <FormLabel bold>{data?.state}</FormLabel>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <FormLabel>{t("productsList.user")}</FormLabel>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <FormLabel bold>{data?.user}</FormLabel>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <FormLabel>{t("productsList.orderHistory.customer")}</FormLabel>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <FormLabel bold>{data?.customer || "-"}</FormLabel>
                            </Grid>
                        </Grid>
                        <Box marginTop="20px">
                            <Subtitle> {t("productsList.orderHistory.delivery")} </Subtitle>
                        </Box>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <FormLabel>{t("productsList.deliveryType.title")}</FormLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <FormLabel bold>{data?.get_delivery_type_display}</FormLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <FormLabel>{t("productsList.deliveryType.pickup")}</FormLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <FormLabel bold>{data?.physical_address || data?.destination_location_label}</FormLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <FormLabel>{t("productsList.sourceLocation")}</FormLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <FormLabel bold>{data?.source_location_label}</FormLabel>
                            </Grid>
                        </Grid>
                        {data.comments && (
                            <>
                                <Box marginTop="20px">
                                    <Subtitle> {t("productsList.commentsTitle")} </Subtitle>
                                </Box>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <FormLabel>{data?.comments}</FormLabel>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </>
                }
                <Box marginTop="20px">
                    <Subtitle> {t("productsList.orderHistory.items")} </Subtitle>
                </Box>
                <ProductListHeader>
                    <ProductListHeaderItem>
                        {t("productsList.tableHeader.AKCode").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem>
                        {t("productsList.orderHistory.vendorCode").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem>
                        {t("productsList.tableHeader.vendor").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem
                        type="orderItem"
                    >
                        {t("productsList.orderHistory.description").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem
                        type="orderItem"
                    >
                        {`${t("productsList.orderHistory.unitPrice").toUpperCase()}, 
                            ${COUNTRY_CURRENCY[currentCountry]}`}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem>
                        {t("productsList.orderHistory.count").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem>
                        {`${t("productsList.orderHistory.total").toUpperCase()}, 
                            ${COUNTRY_CURRENCY[currentCountry]}`}
                    </ProductListHeaderItem>
                </ProductListHeader>
                {isLoading ? (
                    <Box display="flex" alignItems="center" justifyContent="center" marginBottom="40px">
                        <StyledCircularProgress disableShrink/>
                    </Box>
                ) : (data &&
                    Boolean(data.items) &&
                    data.items.map((item) => (
                        <Item
                            orderItem={item}
                            key={item.b2b_item}
                            handleItemPopupOrderHistory={handleItemPopupOrderHistory}
                        />
                    ))
                )
                }
                <CartInfoSection>
                    <CartInfoSectionLine>
                        <CartInfoText>{t("productsList.cartInfoSection.totalWithoutVAT")}</CartInfoText>
                        <CartInfoText>
                            {data && (`${COUNTRY_CURRENCY[currentCountry]} ${data?.total_without_vat}`)}
                        </CartInfoText>
                    </CartInfoSectionLine>
                    <CartInfoSectionLine>
                        <CartInfoText>{t("productsList.cartInfoSection.VAT")} ({data && data?.vat_percentage}%):</CartInfoText>
                        <CartInfoText>{data && (`${COUNTRY_CURRENCY[currentCountry]} ${data?.vat}`)}</CartInfoText>
                    </CartInfoSectionLine>
                    <CartInfoSectionLine>
                        <CartInfoText>
                            <strong>{t("productsList.cartInfoSection.total")}</strong>
                        </CartInfoText>
                        <CartInfoText>
                            <strong>{data && (`${COUNTRY_CURRENCY[currentCountry]} ${data?.total}`)}</strong>
                        </CartInfoText>
                    </CartInfoSectionLine>
                </CartInfoSection>
            </OrderList>
        </Wrapper>
    );
});
