import * as globalActions from "../actions/global";
import api from "../../api";
import resources from "../../api/resources";
import { countryToScope } from "../../translation/languages";
import querystring from "query-string";
import { setNotification } from "../actions/global";
import axios from "axios";
import store from "../store";

let language;

store.subscribe(() => {
    language = store.getState().global.lang;
});

const serialize = (categories) => {
    const serializedCategories = categories.reduce(
        (acc, item, index) => {
            if (item.parentcategoryid && item.parentcategoryid !== "0") {
                return acc.map((itemAcc) => {
                    if (itemAcc.categoryid === item.parentcategoryid) {
                        return {
                            ...itemAcc,
                            children: [
                                ...(itemAcc.children || []),
                                {
                                    units: item?.unitids || [],
                                    title: item.name || "",
                                    count: item?.unitids?.length || 0,
                                    cid: item.name + index || "",
                                    gid: item.name + index || "",
                                },
                            ],
                        };
                    }
                    return itemAcc;
                });
            }
            return [
                ...acc,
                {
                    categoryid: item.categoryid,
                    parentcategoryid: item.parentcategoryid,
                    units: item?.unitids || [],
                    title: item.name || "",
                    count: item?.unitids?.length || 0,
                    cid: item.name + index || "",
                    gid: item.name + index || "",
                },
            ];
        },
        []
    );
    return serializedCategories
}

export const fetchUserData = () => {
    return async (dispatch) => {
        dispatch(globalActions.fetchUserDataRequest());
        try {
            const { data } = await api.get(resources.global.userData);
            return dispatch(globalActions.fetchUserDataSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchUserDataFailure(e));
        }
    };
};

export const fetchMenuProducts = () => {
    return async (dispatch) => {
        dispatch(globalActions.fetchMenuProductsRequest());
        try {
            const { data } = await api.get(
                resources.global.menuData + language
            );
            return dispatch(globalActions.fetchMenuProductsSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchMenuProductsFailure(e));
        }
    };
};

export const fetchVendors = (
    vendorQuery,
    in_stock,
    item_category,
    product_group,
    item_model,
    searchQuery
) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchVendorsRequest());
        try {
            const {
                data: { results },
            } = await api.get(resources.vendors, {
                params: {
                    language,
                    item_category,
                    product_group,
                    item_model,
                    search: searchQuery,
                    ...(in_stock ? { in_stock: true } : {}),
                    // "vendors-search": searchQuery,
                },
            });
            return dispatch(globalActions.fetchVendorsSuccess(results));
        } catch (e) {
            return dispatch(globalActions.fetchVendorsFailure(e));
        }
    };
};

export const fetchProductDetails = (id, page = 1) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchProductDetailsRequest());
        dispatch(fetchFavourites());
        dispatch(fetchAvailabilityNotifications());
        try {
            const { data } = await api.get(resources.products.details, {
                params: {
                    id,
                    page,
                    language,
                },
            });
            const {
                related_items_data,
                referenced_items_data,
                images,
                oem_codes,
                tecdoc_criteria,
                item,
                salesperson,
                min_quantity
            } = data;
            return dispatch(
                globalActions.fetchProductDetailsSuccess({
                    related_items_data,
                    referenced_items_data,
                    images,
                    oem_codes,
                    tecdoc_criteria,
                    item,
                    salesperson,
                    min_quantity
                })
            );
        } catch (e) {
            return dispatch(globalActions.fetchProductDetailsFailure(e));
        }
    };
};

export const fetchProductDetailsVehicles = (id, page = 1) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchProductDetailsVehiclesRequest());

        try {
            const { data } = await api.get(resources.products.vehicles, {
                params: {
                    id,
                    page,
                    language,
                },
            });
            return dispatch(
                globalActions.fetchProductDetailsVehiclesSuccess(data)
            );
        } catch (e) {
            return dispatch(
                globalActions.fetchProductDetailsVehiclesFailure(e)
            );
        }
    };
};

export const fetchItemPurchaseHistory = (id, page = 1) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchItemPurchaseHistoryRequest());

        try {
            const { data } = await api.get(resources.products.purchaseHistory, {
                params: {
                    id,
                    page,
                },
            });
            return dispatch(
                globalActions.fetchItemPurchaseHistorySuccess(data)
            );
        } catch (e) {
            return dispatch(
                globalActions.fetchItemPurchaseHistoryFailure(e)
            );
        }
    };
};

export const fetchPublicProducts = (
    page,
    item_category,
    product_group,
    item_model,
    in_stock,
    vendors,
    search
) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchPublicProductsRequest());
        try {
            const { data } = await api.get(resources.products.publicList, {
                params: {
                    page,
                    language,
                    item_category,
                    product_group,
                    item_model,
                    in_stock,
                    search: search || null,
                    vendors: vendors
                        ? Array.isArray(vendors)
                            ? vendors.join(",")
                            : vendors
                        : null,
                },
            });
            return dispatch(globalActions.fetchPublicProductsSuccess(data));
        } catch (error) {
            return dispatch(globalActions.fetchPublicProductsFailure(error));
        }
    };
};

export const fetchProducts = (
    page,
    item_category,
    product_group,
    item_model,
    in_stock,
    vendors,
    search
) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchProductsRequest());
        dispatch(fetchFavourites());
        dispatch(fetchAvailabilityNotifications());
        dispatch(fetchTags());
        try {
            const { data } = await api.get(resources.products.list, {
                params: {
                    page,
                    language,
                    item_category,
                    product_group,
                    item_model,
                    in_stock,
                    search: search || null,
                    vendors: vendors
                        ? Array.isArray(vendors)
                            ? vendors.join(",")
                            : vendors
                        : null,
                    full_search: true,
                },
            });
            return dispatch(globalActions.fetchProductsSuccess(data));
        } catch (error) {
            return dispatch(globalActions.fetchProductsFailure(error));
        }
    };
};

let token;
export const fetchByCodeList = (
    page,
    item_category,
    product_group,
    item_model,
    in_stock,
    vendors,
    search,
) => {
    return async (dispatch) => {
        if (token) {// if token exists, we cancel it and the api call
            token.cancel();
        }
        dispatch(globalActions.fetchByCodeSearchListRequest());
        token = axios.CancelToken.source();
        try {
            const { data } = await api.get(resources.products.list, {
                params: {
                    page,
                    language,
                    item_category,
                    product_group,
                    item_model,
                    in_stock,
                    search: search || null,
                    vendors: vendors
                        ? Array.isArray(vendors)
                            ? vendors.join(",")
                            : vendors
                        : null,
                },
                cancelToken: token.token,
            })
            return dispatch(globalActions.fetchByCodeSearchListSuccess(data));
        } catch (error) {
            if (!token) {
                return dispatch(globalActions.fetchByCodeSearchListFailure(error));
            }
        }
    };
};

export const updatePrices = (ids, withDiscount, withVAT) => {
    return async (dispatch) => {
        dispatch(globalActions.updatePricesRequest());
        try {
            const { data } = await api.get(resources.products.prices, {
                params: {
                    ids,
                    with_discount: withDiscount,
                    with_vat: withVAT,
                },
            });
            return dispatch(globalActions.updatePricesSuccess(data));
        } catch (error) {
            return dispatch(globalActions.updatePricesFailure(error));
        }
    };
};

export const updatePricesDetails = (ids, withDiscount, withVAT, updateUser=true, relatedPrices=false) => {
    return async (dispatch) => {
        dispatch(globalActions.updatePricesDetailsRequest());
        try {
            const { data } = await api.get(resources.products.prices, {
                params: {
                    ids,
                    with_discount: withDiscount,
                    with_vat: withVAT,
                    update_user: updateUser,
                },
            });
            if (!relatedPrices) {
                return dispatch(globalActions.updatePricesDetailsSuccess(data));
            } else {
                return dispatch(globalActions.updateRelatedPricesDetailsSuccess(data));
            }
        } catch (error) {
            return dispatch(globalActions.updatePricesFailure(error));
        }
    };
};

export const changeCountry = (newCountry) => {
    return async (dispatch) => {
        dispatch(globalActions.changeCountryRequest());
        try {
            const { data } = await api.post(
                resources.global.userData,
                querystring.stringify({
                    scope: countryToScope(newCountry),
                }),
                {
                    headers: {
                        "Content-type": "application/x-www-form-urlencoded",
                    },
                }
            );
            return dispatch(globalActions.changeCountrySuccess(data));
        } catch (error) {
            return dispatch(globalActions.changeCountryFailure(error));
        }
    };
};

export const fetchOemVehicles = (search) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchOemVehiclesRequest());
        try {
            const { data } = await api.get(resources.oemVehicles, {
                params: {
                    language,
                    vin: search || null,
                },
            });

            const { units, categories } = data;
            const serializedCategories = await serialize(categories);

            return dispatch(
                globalActions.fetchOemVehiclesSuccess({
                    units,
                    categories: serializedCategories,
                })
            );
        } catch (error) {
            return dispatch(globalActions.fetchOemVehiclesFailure(error));
        }
    };
};

export const fetchOemVehiclesDetails = (unitId, vin) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchOemVehiclesDetailsRequest());
        try {
            const body = new FormData();
            body.append("vin", vin);
            body.append("unit_id", unitId);
            body.append("language", language);

            const { data } = await api.post(resources.oemVehicles, body, {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            });
            const { details, image, imagemap = [] } = data;

            const updatedMap =
                (Boolean(imagemap.length) ?
                    imagemap.map((item) => ({
                        name: item.code + item.x1,
                        code: item.code,
                        shape: "react",
                        coords: [
                            Number(item.x1),
                            Number(item.y1),
                            Number(item.x2),
                            Number(item.y2),
                        ],
                    })) : []);
            return dispatch(
                globalActions.fetchOemVehiclesDetailsSuccess({
                    details,
                    image,
                    imagemap: updatedMap,
                })
            );
        } catch (e) {
            return dispatch(globalActions.fetchOemVehiclesDetailsFailure(e));
        }
    };
};

export const fetchDetailsByOem = (oem) => {
    return async (dispatch) => {
        try {
            const { data } = await api.get("/oem-items", {
                params: {
                    oem,
                },
            });
            return data;
        } catch (e) {
            return dispatch({ type: "FETCH_OEM_DETAILS_FAILURE" });
        }
    };
};

export const fetchCustomerSearchList = (search) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchCustomerSearchListRequest());
        try {
            const { data } = await api.get("/customer-search", {
                params: {
                    search,
                }
            });
            return dispatch(globalActions.fetchCustomerSearchListSuccess({ data }));
        } catch (e) {
            return dispatch(globalActions.fetchCustomerSearchListFailure(e));
        }
    };
};

export const setImpersonatedCustomer = (id) => {
    return async (dispatch) => {
        dispatch(globalActions.setImpersonatedCustomerRequest());
        try {
            const { data } = await api.get("/impersonate-customer", {
                params: {
                    id,
                }
            });
            if (id !== -1) {
                await api.post(resources.global.userData,
                    querystring.stringify({
                        ...data,
                    }),
                    {
                        headers: {
                            "Content-type": "application/x-www-form-urlencoded",
                        },
                    }
                );
                return (dispatch(globalActions.setImpersonatedCustomerSuccess()))
            }
            else {
                return dispatch(globalActions.clearImpersonatedCustomerSuccess());
            }
        } catch (e) {
            return dispatch(globalActions.setImpersonatedCustomerFailure(e));
        }
    };
};

export const fetchOrderHistoryList = (page = 1) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchOrderHistoryListRequest());
        try {
            const { data } = await api.get("/order-history", {
                params: {
                    page,
                    language,
                }
            });
            return dispatch(globalActions.fetchOrderHistoryListSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchOrderHistoryListFailure(e));
        }
    };
};

export const fetchOrderHistoryItem = (id) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchOrderHistoryItemRequest());
        try {
            const { data } = await api.get("/order-history-item", {
                params: {
                    language,
                    id,
                }
            });
            return dispatch(globalActions.fetchOrderHistoryItemSuccess({ data }));
        } catch (e) {
            return dispatch(globalActions.fetchOrderHistoryItemFailure(e));
        }
    };
};

export const fetchPriceOfferHistoryItem = (id) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchPriceOfferHistoryItemRequest());
        try {
            const { data } = await api.get("/price-offer-history-item", {
                params: {
                    language,
                    id,
                }
            });
            return dispatch(globalActions.fetchPriceOfferHistoryItemSuccess({ data }));
        } catch (e) {
            return dispatch(globalActions.fetchPriceOfferHistoryItemFailure(e));
        }
    };
};

export const fetchPriceOfferHistoryList = (page = 1) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchPriceOfferHistoryListRequest());
        try {
            const { data } = await api.get("/price-offer-history", {
                params: {
                    page,
                    language,
                }
            });
            return dispatch(globalActions.fetchPriceOfferHistoryListSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchPriceOfferHistoryListFailure(e));
        }
    };
};

export const fetchTopProducts = () => {
    return async (dispatch) => {
        dispatch(globalActions.fetchTopProductsRequest());
        dispatch(fetchFavourites());
        dispatch(fetchAvailabilityNotifications());
        dispatch(fetchTags());
        try {
            const { data } = await api.get("/top-order-items", {
                params: {
                    language,
                }
            });
            return dispatch(globalActions.fetchTopProductsSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchTopProductsFailure(e));
        }
    };
};

export const fetchFavourites = () => {
    return async (dispatch) => {
        dispatch(globalActions.fetchFavouritesRequest());
        try {
            const { data } = await api.get(resources.favorite, {
                params: {
                    language,
                }
            });
            return dispatch(globalActions.fetchFavouritesSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchFavouritesFailure(e));
        }
    };
};

export const setFavourite = (item_id, itemName) => {
    return async (dispatch) => {
        dispatch(globalActions.setFavoriteRequest());
        try {
            await api.post(resources.favorite, {
                item_id,
            });
            dispatch(
                setNotification(
                    {
                        itemId: item_id,
                        actionName: "addedFav",
                        itemName
                    }
                )
            );
            return dispatch(globalActions.setFavoriteSuccess());
        } catch (e) {
            if (e.response && e.response.status === 429) {
                dispatch(
                    setNotification({
                        itemId: item_id,
                        actionName: "tooManyFav",
                        itemName
                    })
                );
            }
            return dispatch(globalActions.setFavoriteFailure(e));
        }
    };
};

export const removeFavourite = (item_id, itemName) => {
    return async (dispatch) => {
        dispatch(globalActions.removeFavoriteRequest());
        try {
            await api.delete(resources.favorite, {
                params: {
                    item_id,
                }
            });
            dispatch(
                setNotification(
                    {
                        itemId: item_id,
                        actionName: 'removedFav',
                        itemName
                    }
                )
            );
            return dispatch(globalActions.removeFavoriteSuccess());
        } catch (e) {
            return dispatch(globalActions.removeFavoriteFailure(e));
        }
    };
};

export const fetchAvailabilityNotifications = () => {
    return async (dispatch) => {
        dispatch(globalActions.fetchAvailabilityNotificationsRequest());
        try {
            const { data } = await api.get(resources.availabilityNotifications, {
                params: {
                    language,
                }
            });
            return dispatch(globalActions.fetchAvailabilityNotificationsSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchAvailabilityNotificationsFailure(e));
        }
    };
};

export const setAvailabilityNotifications = (item_id, itemName) => {
    return async (dispatch) => {
        dispatch(globalActions.setAvailabilityNotificationsRequest());
        try {
            await api.post(resources.availabilityNotifications, {
                item_id,
            });
            dispatch(
                setNotification(
                    {
                        itemId: item_id,
                        actionName: 'addedAvailNotification',
                        itemName
                    }
                )
            );
            return dispatch(globalActions.setAvailabilityNotificationsSuccess());
        } catch (e) {
            return dispatch(globalActions.setAvailabilityNotificationsFailure(e));
        }
    };
};

export const removeAvailabilityNotifications = (item_id, itemName) => {
    return async (dispatch) => {
        dispatch(globalActions.removeAvailabilityNotificationsRequest());
        try {
            await api.delete(resources.availabilityNotifications, {
                params: {
                    item_id,
                }
            });
            dispatch(
                setNotification(
                    {
                        itemId: item_id,
                        actionName: 'removedAvailNotification',
                        itemName
                    }
                )
            );
            return dispatch(globalActions.removeAvailabilityNotificationsSuccess());
        } catch (e) {
            return dispatch(globalActions.removeAvailabilityNotificationsFailure(e));
        }
    };
};

export const fetchTags = () => {
    return async (dispatch) => {
        dispatch(globalActions.fetchTagsRequest());
        try {
            const { data } = await api.get(resources.tags.list);
            return dispatch(globalActions.fetchTagsSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchTagsFailure(e));
        }
    };
};

export const deleteTag = (id, title) => {
    return async (dispatch) => {
        dispatch(globalActions.deleteTagRequest());
        try {
            await api.delete(resources.tags.drop, {
                params: {
                    tag_id: id
                }
            });
            dispatch(fetchTags());
            dispatch(
                setNotification(
                    {
                        tagTitle: title,
                        actionName: 'deleteTag',
                    }
                )
            );
            return dispatch(globalActions.deleteTagSuccess());
        } catch (e) {
            return dispatch(globalActions.deleteTagFailure(e));
        }
    };
};

export const fetchTagItems = (id) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchTagItemsRequest());
        try {
            const { data } = await api.get(resources.products.list, {
                params: {
                    tag_id: id,
                    language,
                }
            });
            const allData = Object.assign(data, { tag_id: id });
            return dispatch(globalActions.fetchTagItemsSuccess(allData));
        } catch (e) {
            return dispatch(globalActions.fetchTagItemsFailure(e));
        }
    };
};

export const fetchItemTagList = (id) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchItemTagListRequest());
        try {
            const { data } = await api.get(resources.tags.itemTagList, {
                params: {
                    id: id,
                }
            });
            return dispatch(globalActions.fetchItemTagListSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchItemTagListFailure(e));
        }
    };
};

export const setTagItem = (title, id, itemName) => {
    return async (dispatch) => {
        dispatch(globalActions.setTagItemRequest());
        try {
            await api.post(resources.tags.create, {
                title: title,
                item_id: id
            });
            dispatch(
                setNotification(
                    {
                        tagTitle: title,
                        itemId: id,
                        actionName: 'setTagItem',
                        itemName
                    }
                )
            );
            dispatch(fetchTags());
            return dispatch(globalActions.setTagItemSuccess());
        } catch (e) {
            return dispatch(globalActions.setTagItemFailure(e));
        }
    };
};

export const removeTagItem = (tagId, tagTitle, itemId, itemName) => {
    return async (dispatch) => {
        dispatch(globalActions.removeTagItemRequest());
        try {
            await api.delete(resources.tags.itemDrop, {
                params: {
                    tag_id: tagId,
                    item_id: itemId
                }
            });
            dispatch(fetchTags());
            dispatch(
                setNotification(
                    {
                        itemId: itemId,
                        actionName: 'removeTagItem',
                        itemName: itemName,
                        tagTitle: tagTitle,
                    }
                )
            );
            return dispatch(globalActions.removeTagItemSuccess());
        } catch (e) {
            return dispatch(globalActions.removeTagItemFailure(e));
        }
    };
};

export const setComment = (comment, id, itemName) => {
    return async (dispatch) => {
        dispatch(globalActions.setCommentRequest());
        try {
            await api.post(resources.comments.create, {
                comment: comment,
                item_id: id
            });
            dispatch(
                setNotification(
                    {
                        itemId: id,
                        actionName: 'commentSet',
                        itemName
                    }
                )
            );
            return dispatch(globalActions.setCommentSuccess());
        } catch (e) {
            if (e.response.data[0] === "Already exists") {
                dispatch(
                    setNotification(
                        {
                            itemId: id,
                            actionName: 'commentExists',
                            itemName
                        }
                    )
                );
            }
            return dispatch(globalActions.setCommentFailure(e));
        }
    };
};

export const removeComment = (id, itemName) => {
    return async (dispatch) => {
        dispatch(globalActions.removeCommentRequest());
        try {
            await api.delete(resources.comments.drop, {
                params: {
                    item_id: id
                }
            });
            dispatch(
                setNotification(
                    {
                        itemId: id,
                        actionName: 'commentRemoved',
                        itemName
                    }
                )
            );
            return dispatch(globalActions.removeCommentSuccess());
        } catch (e) {
            return dispatch(globalActions.removeCommentFailure(e));
        }
    };
};

export const fetchYqCatalog = () => {
    return async (dispatch) => {
        dispatch(globalActions.fetchYqCatalogRequest());
        try {
            const { data } = await api.get('/yq-catalogs', {
                params: {
                    language,
                }
            });
            return dispatch(globalActions.fetchYqCatalogSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchYqCatalogFailure(e));
        }
    };
};

export const fetchYqParams = (code, sdd = null) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchYqParamsRequest());
        try {
            const { data } = await api.get('/yq-wizard2-params', {
                params: {
                    catalog: code,
                    ssd: sdd,
                    language,
                }
            }
            );
            return dispatch(globalActions.fetchYqParamsSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchYqParamsFailure(e));
        }
    };
};

export const fetchYqListVehicles = (code, sdd = null) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchYqListVehiclesRequest());
        try {
            const { data } = await api.get('/yq-wizard2-search', {
                params: {
                    catalog: code,
                    ssd: sdd,
                    language,
                }
            }
            );
            return dispatch(globalActions.fetchYqListVehiclesSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchYqListVehiclesFailure(e));
        }
    };
};

export const fetchYqFindVehicle = (vin, catalog) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchYqFindVehicleRequest());
        try {
            const { data } = await api.get('/yq-find-vehicle', {
                params: {
                    vin: vin,
                    catalog: catalog,
                    language,
                }
            }
            );
            return dispatch(globalActions.fetchYqFindVehicleSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchYqFindVehicleFailure(e));
        }
    };
};

// export const fetchYqVehicleInfo = (catalog, id, ssd) => {
//     return async (dispatch) => {
//         dispatch(globalActions.fetchYqVehicleInfoRequest());
//         try {
//             const { data } = await api.get('/yq-vehicle-info', {
//                 params: {
//                     catalog: catalog,
//                     vehicle_id: id,
//                     ssd: ssd,
//                 }
//             }
//             );
//             // return dispatch(globalActions.fetchYqVehicleInfoSuccess(data));
//         } catch (e) {
//             return dispatch(globalActions.fetchYqVehicleInfoFailure(e));
//         }
//     };
// };

export const fetchYqOemVehicle = (catalog, id, ssd) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchYqOemVehicleRequest());
        try {
            const { data } = await api.get('/oem-yq-vehicle', {
                params: {
                    catalog: catalog,
                    vehicle_id: id,
                    ssd: ssd,
                    language
                }
            });
            const { units, categories, vehicle, vehicle_name } = data;
            const serializedCategories = await serialize(categories);

            return dispatch(globalActions.fetchYqOemVehicleSuccess({
                units,
                categories: serializedCategories,
                vehicle,
                vehicle_name
            }))
        } catch (e) {
            return dispatch(globalActions.fetchYqOemVehicleFailure(e));
        }
    };
};

export const fetchYqOemVehicleDetails = (catalog, ssd, id, unitId) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchOemVehiclesDetailsRequest());
        try {
            const body = new FormData();
            body.append("catalog", catalog);
            body.append("ssd", ssd);
            body.append("vehicle_id", id);
            body.append("unit_id", unitId);
            body.append("language", language);

            const { data } = await api.post('/oem-yq-vehicle', body, {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            });
            const { details, image, imagemap = [] } = data;

            const updatedMap =
                (Boolean(imagemap.length) ?
                    imagemap.map((item) => ({
                        name: item.code + item.x1,
                        code: item.code,
                        shape: "react",
                        coords: [
                            Number(item.x1),
                            Number(item.y1),
                            Number(item.x2),
                            Number(item.y2),
                        ],
                    })) : []);

            return dispatch(
                globalActions.fetchOemVehiclesDetailsSuccess({
                    details,
                    image,
                    imagemap: updatedMap,
                })
            );
        } catch (e) {
            return dispatch(globalActions.fetchOemVehiclesDetailsFailure(e));
        }
    };
};

export const fetchPlateVehicles = (code, search) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchPlateVehiclesRequest());
        try {
            const { data } = await api.get('/plate-vehicle', {
                params: {
                    language,
                    plate_number: search || null,
                    country_code: code,
                },
            });
            const { units, categories } = data;
            const serializedCategories = await serialize(categories);

            return dispatch(
                globalActions.fetchPlateVehiclesSuccess({
                    units,
                    categories: serializedCategories,
                })
            );
        } catch (error) {
            return dispatch(globalActions.fetchPlateVehiclesFailure(error));
        }
    }
}

export const fetchPlateVehiclesDetails = (code, plate, unitId) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchOemVehiclesDetailsRequest());
        try {
            const body = new FormData();
            body.append("plate_number", plate);
            body.append("unit_id", unitId);
            body.append("country_code", code);
            body.append("language", language);

            const { data } = await api.post('/plate-vehicle', body, {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            });
            const { details, image, imagemap = [] } = data;

            const updatedMap =
                (Boolean(imagemap.length) ?
                    imagemap.map((item) => ({
                        name: item.code + item.x1,
                        code: item.code,
                        shape: "react",
                        coords: [
                            Number(item.x1),
                            Number(item.y1),
                            Number(item.x2),
                            Number(item.y2),
                        ],
                    })) : []);
            return dispatch(
                globalActions.fetchOemVehiclesDetailsSuccess({
                    details,
                    image,
                    imagemap: updatedMap,
                })
            );
        } catch (e) {
            return dispatch(globalActions.fetchOemVehiclesDetailsFailure(e));
        }
    };
};

export const fetchNewProducts = (page = 1, scope) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchNewProductsRequest());
        dispatch(fetchFavourites());
        dispatch(fetchTags());
        try {
            const { data } = await api.get("/newest-items-list", {
                params: {
                    page: page,
                    scope: scope,
                    language,
                }
            });
            return dispatch(globalActions.fetchNewProductsSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchNewProductsFailure(e));
        }
    };
};

export const fetchInvoices = () => {
    return async (dispatch) => {
        dispatch(globalActions.fetchInvoicesRequest());
        try {
            const { data } = await api.get("/invoices")
            return dispatch(globalActions.fetchInvoicesSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchInvoicesFailure(e));
        }
    };
};

export const fetchItemReturnList = (page = 1) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchItemReturnListRequest());
        try {
            const { data } = await api.get("/item-returns", {
                params: {page}
            });
            return dispatch(globalActions.fetchItemReturnListSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchItemReturnListFailure(e));
        }
    };
};

export const fetchItemReturnItem = (id) => {
    return async (dispatch) => {
        dispatch(globalActions.fetchItemReturnItemRequest());
        try {
            const { data } = await api.get("/item-returns-item", {
                params: {id}
            });
            return dispatch(globalActions.fetchItemReturnItemSuccess({ data }));
        } catch (e) {
            return dispatch(globalActions.fetchItemReturnItemFailure(e));
        }
    };
};

export const submitReturnForm = (data) => {
    return async (dispatch) => {
        dispatch(globalActions.itemReturnRequest());
        try {
            const formData = new FormData();
            formData.append("customer", data.customer);
            formData.append("item_number", data.item_number);
            formData.append("doc_number", data.doc_number);
            formData.append("count", data.count);
            formData.append("purchase_date", data.purchase_date);
            formData.append("registered_office", data.registered_office);
            formData.append("item_installed_date", data.item_installed_date);
            formData.append("item_removed_date", data.item_removed_date);
            formData.append("item_installed_odo", data.item_installed_odo);
            formData.append("item_removed_odo", data.item_removed_odo);
            formData.append("vehicle_identity", data.vehicle_identity);
            formData.append("phone", data.phone);
            formData.append("additional_email", data.additional_email);
            formData.append("return_if_rejected", data.return_if_rejected);
            formData.append("description", data.description);
            formData.append("shop_returned", data.shop_returned);
            for (const file of data.files) {
                formData.append("files", file);
            }
            const response = await api.post(resources.returnItem, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            return dispatch(globalActions.itemReturnSuccess(response.data));
        } catch (error) {
            return dispatch(
                globalActions.itemReturnFailure(error.response.data)
            );
        }
    };
};

export const fetchReturnFormData = () => {
    return async (dispatch) => {
        dispatch(globalActions.fetchReturnFormDataRequest());
        try {
            const { data } = await api.get(resources.returnItem);
            return dispatch(globalActions.fetchReturnFormDataSuccess(data));
        } catch (e) {
            return dispatch(globalActions.fetchReturnFormDataFailure(e));
        }
    };
};

export const SET_LOGGING_IN = "SET_LOGGING_IN";
 
export const setLoggingIn = (loggingIn) => ({
    type: SET_LOGGING_IN,
    payload: loggingIn,
});

export const verifyAKCode = (akCode) => {
    return async (dispatch) => {
        dispatch(globalActions.verifyAKCodeRequest());
        try {
            const { data } = await api.post(resources.vefiryAKCode, {code: akCode});
            return dispatch(globalActions.verifyAKCodeSuccess(data));
        } catch (e) {
            return dispatch(globalActions.verifyAKCodeFailure(e));
        }
    };
};
