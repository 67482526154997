import React, { memo, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Item from "../../../components/Listing/item";
import {
    ProductListHeader,
    ProductListHeaderItem
} from "../../../components/Listing/style";
import { Title, Wrapper } from "../../../components/SideListening/style";
import { fetchOrderHistoryList } from "../../../core/redux/asyncActions/global";
import { COUNTRY_CURRENCY } from "../../../core/utils";
import { OrderList } from '../../Order/style';
import { ItemDetailsModal } from "../../../components/Listing/components/ItemDetailsModal";
import StyledPagination from "../../../components/Pagination";
import { StyledCircularProgress } from "../../../components/CircularProgress";
import { setSearchQuery } from "../../../core/redux/actions/global";

export const OrderSide = memo(() => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const setPageNumber = (pageNumber) => {
        setPage(pageNumber);
    };

    const {
        orderHistoryList = [],
        lang,
    } = useSelector((state) => state.global);

    const currentProduct = useSelector(
        (state) => state.global?.currentProduct || null
    );

    const { scope: currentCountry } = useSelector(
        (state) => state.global.userData
    );

    useEffect(() => {
        async function fetchData() {
            const results = await dispatch(fetchOrderHistoryList(page));
            if (results.type !== "FETCH_ORDER_HISTORY_LIST_FAILURE") {
                setTotalPages(results?.payload?.count / 25);
            } else {
                setPage(1);
            }
        }
        fetchData();
    }, [
        lang,
        page,
        dispatch,
        currentCountry,
    ]);

    useEffect(() => {
        dispatch(setSearchQuery(""));
    }, [dispatch])

    return (
        <Wrapper>
            {currentProduct && (
                <ItemDetailsModal />
            )}
            <Title>{t("productsList.orderHistory.title").toUpperCase()}</Title>
            <OrderList>
                <ProductListHeader>
                    <ProductListHeaderItem>
                        {t("productsList.orderHistory.number").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem
                        type="order"
                    >
                        {t("productsList.orderHistory.created").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem>
                        {t("productsList.orderHistory.state").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem>
                        {t("productsList.orderHistory.user").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem
                        type="order"
                    >
                        {t("productsList.orderHistory.customer").toUpperCase()}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem>
                        {`${t("productsList.orderHistory.total").toUpperCase()}, 
                            ${COUNTRY_CURRENCY[currentCountry]}`}
                    </ProductListHeaderItem>
                    <ProductListHeaderItem
                        type="order"
                    >
                        {t("productsList.orderHistory.sourceLocation").toUpperCase()}
                    </ProductListHeaderItem>
                </ProductListHeader>
                {orderHistoryList &&
                    (orderHistoryList.isLoading ? (
                        <Box display="flex" alignItems="center" justifyContent="center" margin="40px">
                            <StyledCircularProgress disableShrink/>
                        </Box>
                    ) : (
                        Boolean(orderHistoryList.data) && (
                            orderHistoryList.data.map((order) => (
                                <Item
                                    order={order}
                                    key={order.number}
                                    type="order"
                                />
                            ))
                        )
                    )
                    )
                }
            </OrderList>
            {!orderHistoryList.isLoading &&
                <Box style={{ display: "flex", justifyContent: "center" }}>
                    <StyledPagination
                        count={totalPages}
                        pageNumber={page}
                        setPageNumber={setPageNumber}
                    />
                </Box>}
        </Wrapper>
    );
});
