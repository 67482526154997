import React, { useState, useEffect } from "react";
import { StyledDataGrid } from "../../../components/DataGrid";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    ItemCardWrapper,
    ItemSpec,
    TitleWrapper,
} from "../../../components/Listing/components/ItemCard/style";
import { format } from "date-fns";
import StyledPagination from "../../../components/Pagination";

export const Table = ({ invoices, isLoading }) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const columns = [
        {
            field: "date",
            type: "date",
            headerName: `${t("invoices.invoiceDate")}`,
            flex: 1,
            valueFormatter: params => format(params?.value, "dd.MM.yyyy"),
        },
        {
            field: "number",
            headerName: `${t("invoices.invoiceNumber")}`,
            flex: 1,
        },
        {
            field: "total",
            type: "number",
            headerName: `${t("invoices.totalValue")}`,
            flex: 1,
            valueFormatter: params => parseFloat(params?.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        },
        {
            field: "remaining",
            type: "number",
            headerName: `${t("invoices.remainingValue")}`,
            flex: 1,
            valueFormatter: params => parseFloat(params?.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        },
        {
            field: "due",
            type: "date",
            headerName: `${t("invoices.dueDate")}`,
            flex: 1,
            valueFormatter: params => format(params?.value, "dd.MM.yyyy"),
        },
        {
            field: "status",
            headerName: `${t("invoices.status")}`,
            flex: 1,
        }
    ];
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const sortedInvoices = [...invoices].sort((a, b) => new Date(b.date) - new Date(a.date));
    useEffect(() => {
        setTotalPages(invoices.length / 50);
    }, [invoices.length]);

    if (isPhoneScreen) {
        return (
            <Grid container justifyContent="center" spacing={1}>
                {sortedInvoices && sortedInvoices.slice(page === 1 ? 0 : (page - 1) * 50, page * 50 - 1).map((row, i) => (
                    <Grid item xs={12} key={i}>
                        <ItemCardWrapper>
                            <Box flex="1" display="flex" flexDirection="column">
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    marginBottom="12px"
                                >
                                    <TitleWrapper style={{ cursor: "default" }}>{row.number}</TitleWrapper>
                                </Box>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <ItemSpec>{`${t(
                                            "invoices.invoiceDate"
                                        )}: ${format(row.date, "dd.MM.yyyy")}`}</ItemSpec>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ItemSpec>{`${t(
                                            "invoices.totalValue"
                                        )}: ${row.total}`}</ItemSpec>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ItemSpec>{`${t(
                                            "invoices.remainingValue"
                                        )}: ${row.remaining}`}</ItemSpec>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ItemSpec>{`${t(
                                            "invoices.dueDate"
                                        )}: ${format(row.due, "dd.MM.yyyy")}`}</ItemSpec>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ItemSpec>{`${t(
                                            "invoices.status"
                                        )}: ${row.status}`}</ItemSpec>
                                    </Grid>
                                </Grid>
                            </Box>
                        </ItemCardWrapper>
                    </Grid>
                ))}
                <Grid item justifyContent="center" width="100%">
                    <StyledPagination
                        pageNumber={page}
                        count={totalPages}
                        setPageNumber={(pageNumber) => setPage(pageNumber)}
                    />
                </Grid>
            </Grid>
        );
    }

    return (
        <Box margin="40px 0" width="100%" height="55vh">
            <StyledDataGrid
                columns={columns}
                rows={sortedInvoices}
                disableColumnMenu={true}
                disableSelectionOnClick
                hideFooterPagination={invoices.length > 100 ? false : true}
                pageSize={100}
                rowsPerPageOptions={[100]}
                hideFooter={invoices.length > 100 ? false : true}
                loading={isLoading}
                components={{
                    NoRowsOverlay: () => (
                        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", top: "56px" }}>{t("dataGrid.noResults")}</div>
                    ),
                    NoResultsOverlay: () => (
                        <></>
                    ),
                }}
            />
        </Box>
    );
};
