import {
    UserMenuActionButton,
    UserMenuArrow,
    Overlay,
    UserMenuSearchInput,
    UserMenuSearchWrapper,
    UserMenuSelectLabel,
    UserMenuSeparator,
    UserMenuWrapper,
    BoxVariantUserItem,
    BoxVariantItemTitle,
    BoxVariantItemDescription,
    SearchCategoryButton,
    BoxVariantsUser,
} from "./../style";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../../core/redux/actions/global";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { COUNTRIES, LANGUAGES } from "../../../core/translation/languages";
import { changeCountry, fetchCustomerSearchList, setImpersonatedCustomer, fetchUserData } from "../../../core/redux/asyncActions/global";
import { ORDER_HISTORY_PATH, HOME_PATH, INVOICES_PATH, ITEM_RETURN_FORM_PATH, PRICE_OFFER_HISTORY_PATH } from "../../../core/routes";
import { StyledCircularProgress } from "../../CircularProgress";

export default function UserMenu({ overlayClick, onLogout, page }) {
    const dispatch = useDispatch();
    const { lang, country, userData, customerSearchList = [] } = useSelector((state) => state.global);

    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const userMenuRef = useRef(null);

    const handleLanguageChange = (e) => {
        const { value } = e.target;
        dispatch(changeLanguage(value));
        overlayClick();
    };

    const setFocusSearchField = async (e) => {
        const { value } = e.target;
        await dispatch(fetchCustomerSearchList(value ? value : " "));
    };

    const handleImpersonateCustomerSelect = async (id) => {
        await dispatch(setImpersonatedCustomer(id ? id : -1));
        history.replace("/");
        history.push(location.pathname);
        await dispatch(fetchUserData());
    };

    const handleLoginClick = () => {
        history.push(HOME_PATH);
    };

    const handleClick = (e) => {
        if (
            userMenuRef.current &&
            !userMenuRef.current.contains(e.target) &&
            e.target !== document.body
        ) {
            overlayClick();
        }
    };

    const handleCountryChange = (e) => {
        const { value } = e.target;
        dispatch(changeCountry(value));
        overlayClick();
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    return (
        <>
            <Overlay />
            <UserMenuWrapper
                ref={userMenuRef}
                onClick={(e) => e.stopPropagation()}
            >
                <UserMenuArrow />
                {userData && userData.can_switch_customer && (
                    <>
                        {(userData.impersonated_customer_id !== null) &&
                            <UserMenuActionButton dark
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleImpersonateCustomerSelect(-1)
                                }}>
                                {t("headerUserMenu.stopImpersonation").toUpperCase()}
                            </UserMenuActionButton>
                        }
                        <UserMenuSearchWrapper>
                            <UserMenuSearchInput
                                type="text"
                                placeholder={t(
                                    "headerUserMenu.impersonateCustomer"
                                )}
                                onChange={setFocusSearchField}
                                onClick={(e) => e.stopPropagation()}
                            />
                            <SearchCategoryButton>
                                <SearchIcon />
                            </SearchCategoryButton>
                        </UserMenuSearchWrapper>
                        {(customerSearchList && customerSearchList.isLoading) ?
                            <Box
                                display="flex"
                                alignItems="center"
                                width="100%"
                                height="100%"
                                justifyContent="center"
                                padding="15px 0 0 0"
                            >
                                <StyledCircularProgress disableShrink />
                            </Box>
                            : Boolean(customerSearchList.data && customerSearchList.data.length) && (
                                <BoxVariantsUser>
                                    {customerSearchList.data.slice(0, 5).map(({ name, scope, id, registration_number }) => (
                                        <BoxVariantUserItem
                                            key={id}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleImpersonateCustomerSelect(id);
                                            }}
                                        >
                                            <BoxVariantItemTitle>
                                                {name} {" "} ({scope})
                                            </BoxVariantItemTitle>
                                            <BoxVariantItemDescription>
                                                {registration_number}
                                            </BoxVariantItemDescription>

                                        </BoxVariantUserItem>
                                    ))}
                                </BoxVariantsUser>
                            )}
                        <UserMenuSeparator />
                    </>
                )}
                {userData && userData.can_switch_language && (
                    <>
                        <UserMenuSelectLabel>
                            {t("headerUserMenu.switchLanguage")}
                        </UserMenuSelectLabel>
                        <FormControl variant="outlined" size="small">
                            <Select
                                value={lang}
                                onClick={(e) => e.stopPropagation()}
                                onChange={handleLanguageChange}
                            >
                                {Object.values(LANGUAGES).map((lang) => (
                                    <MenuItem value={lang.id} key={lang.id}>
                                        {lang.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </>
                )}
                {userData && userData.can_switch_country && (
                    <>
                        <UserMenuSelectLabel>
                            {t("headerUserMenu.switchCountry")}
                        </UserMenuSelectLabel>
                        <FormControl variant="outlined" size="small">
                            <Select
                                value={country}
                                onChange={handleCountryChange}
                            >
                                {COUNTRIES.map((currentCountry) => (
                                    <MenuItem
                                        key={currentCountry}
                                        value={currentCountry}
                                    >
                                        {currentCountry}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </>
                )}
                {userData && (
                    <>
                        <Link to={ORDER_HISTORY_PATH} style={{ textDecoration: "none" }}>
                            <UserMenuActionButton style={{ marginBottom: "8px" }}>
                                {t("headerUserMenu.orderHistory").toUpperCase()}
                            </UserMenuActionButton>
                        </Link>
                        {userData.is_salesperson && (
                            <Link to={PRICE_OFFER_HISTORY_PATH} style={{ textDecoration: "none" }}>
                                <UserMenuActionButton style={{ marginBottom: "8px" }}>
                                    {t("headerUserMenu.priceOffersHistory").toUpperCase()}
                                </UserMenuActionButton>
                            </Link>
                        )}
                        <Link to={INVOICES_PATH} style={{ textDecoration: "none" }}>
                            <UserMenuActionButton style={{ marginBottom: "8px" }}>
                                {t("invoices.invoices").toUpperCase()}
                            </UserMenuActionButton>
                        </Link>
                        {(userData.is_salesperson && !userData.is_customer && userData.has_email)
                        || (userData.is_customer && !userData.is_salesperson && userData.has_email) ? (
                            <Link to={ITEM_RETURN_FORM_PATH} style={{ textDecoration: "none" }}>
                                <UserMenuActionButton style={{ marginBottom: "8px" }}>
                                    {t("headerUserMenu.itemReturns").toUpperCase()}
                                </UserMenuActionButton>
                            </Link>
                        ) : null}
                    </>
                )}
                {(page === "publicSearch") ?
                    <>
                        <UserMenuSelectLabel>
                            {t("headerUserMenu.switchLanguage")}
                        </UserMenuSelectLabel>
                        <FormControl variant="outlined" size="small">
                            <Select
                                value={lang}
                                onClick={(e) => e.stopPropagation()}
                                onChange={handleLanguageChange}
                            >
                                {Object.values(LANGUAGES).map((lang) => (
                                    <MenuItem value={lang.id} key={lang.id}>
                                        {lang.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <UserMenuActionButton dark onClick={handleLoginClick}>
                            {t("login.login").toUpperCase()}
                        </UserMenuActionButton>
                    </>
                    :
                    <UserMenuActionButton dark onClick={onLogout}>
                        {t("headerUserMenu.logout").toUpperCase()}
                    </UserMenuActionButton>
                }
            </UserMenuWrapper>
        </>
    );
}
